.order-details{
    padding-top: 8rem;
}

.order-details-container {
    max-width: 990px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    background-color: #fdfdfd;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;

}

.order-header {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
}

.order-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.client-info,
.order-status-container,
.payment-info {
    flex: 1;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f8f9fa;
    margin: 0 10px;
}


.status-container {
    display: flex;
    align-items: center;
}

.status-bubble {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
}

.status-text {
    font-size: 18px;
    color: #333;
    font-weight: bold;


}

.status-bubble.recue {
    background-color: red; /* Rouge pour "Reçue" */
}

.status-bubble.en-cours {
    background-color: orange; /* Orange pour "En cours" */
}

.status-bubble.terminee {
    background-color: green; /* Vert pour "Terminée" */
}



.status-text {
    font-size: 18px;
    color: #333;
    font-weight: bold;
}

.menu-details {
    margin-bottom: 20px;
}

.menu-details h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
}

.menu-details ul {
    list-style: none;
    padding: 20px;
}

.menu-details li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.menu-image {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 15px;
}

.menu-info {
    flex-grow: 1;

}

.menu-name {
    font-size: 18px;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 8px;
}

.menu-quantity,
.menu-price {
    display: block;
    font-size: 16px;
    color: #7f8c8d;
}

.menu-extras {
    margin-top: 10px;
    font-size: 14px;
    color: #34495e;
}
.total-price-delivery {
    font-size: 1.2em;
    font-weight: bold;
    margin: 10px 0;
}
.order-date-time {
    text-align: right;
    font-size: 16px;
    color: #7f8c8d;
    margin-top: 20px;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
    .order-info {
        flex-direction: column; /* Stack items vertically */
    }

    .client-info,
    .order-status-container,
    .payment-info {
        margin: 10px 0;
        padding: 15px;
    }

    .menu-details li {
        align-items: flex-start;
    }

    .menu-image {
        margin-bottom: 10px;
    }

    .order-header {
        font-size: 24px;
    }

    .menu-details h3 {
        font-size: 18px;
    }
}
