/* General Styles */
.cart-contents {
    padding-top: 8rem;
    min-height: calc(100vh - 150px); /* Adjust based on your footer height */
    display: flex;
    flex-direction: column;
}

.cart-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    flex: 1; /* Ensure the container grows to fill available space */
}

.cart-header {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
}

.cart-item {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    background-color: #f9f9f9;
    border-radius: 8px;
    position: relative;
    transition: transform 0.2s;
    overflow: hidden;
}

.cart-item:hover {
    transform: scale(1.02);
}

.cart-item img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    object-fit: cover;
    margin-right: 20px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.cart-item img:hover {
    transform: scale(1.1);
}

.cart-item img::after {
    content: '\1F50D';
    font-size: 2em;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 5px;
    right: 5px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.cart-item img:hover::after {
    opacity: 1;
}

.cart-item img:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    pointer-events: none;
    transition: border-color 0.3s ease-in-out;
}

.cart-item img:hover::before {
    border-color: rgba(0, 0, 0, 0.5);
}


.cart-item-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.cart-item h2 {
    font-size: 1.5em;
    margin: 0;
    color: #444;
    line-height: 1.2;
}

.cart-item p {
    font-size: 1em;
    margin: 5px 0;
    color: #555;
}
.cart-summary{
    font-weight: bold;

}
.menu-total-price,
.total-price {
    font-size: 1.2em;
    font-weight: bold;
    color: #e63946;
    margin: 10px 0;
}


.total-description,
.delivery-info {
    color: #6c757d; /* Couleur gris clair pour le texte */
}





.empty-cart-message {
    text-align: center;
    font-size: 1.2em;
    color: #777;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 8px;
    margin: 20px 0;
}

.order-button-container {
    text-align: center;
    margin-top: 30px;
}

.order-button {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.order-button:hover {
    background-color: #c0392b;
}

.order-button-container {
    position: relative; /* Ensure the container is positioned relatively */
}

.order-button:disabled {
    background-color: gray;
    cursor: not-allowed;
}

.order-button-container .disabled-message {
    position: fixed; /* Fix the message to the viewport */
    bottom: 20px; /* Adjust this value to move the message higher or lower */
    left: 50%;
    transform: translateX(-50%);
    background-color: red;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9em;
    z-index: 100;
    display: none; /* Hide by default, will show on hover */
}

.order-button-container:hover .disabled-message {
    display: block;
}



.delete-icon {
    color: red;
    cursor: pointer;
    font-size: 1.5em;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: color 0.3s;
}

.delete-icon:hover {
    color: #c0392b;
}

.cart-item ul {
    list-style: none;
    padding: 0;
}

.cart-item li {
    font-size: 0.9em;
    color: #666;
    margin: 3px 0;
}

.quantity-select {
    margin-left: 10px;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1em;
    transition: border-color 0.3s;
}

.quantity-select:focus {
    border-color: red;
    outline: none;
}

@media (max-width: 480px) {
    .cart-container {
        width: 90%;
        margin: 0 auto;
        padding: 15px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        font-family: 'Roboto', sans-serif;
    }

    .cart-header {
        font-size: 1.8em;
        margin-bottom: 15px;
    }

    .cart-item {
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 10px;
    }

    .cart-item:hover {
        transform: scale(1.03);
    }

    .cart-item-image {
        width: 60px;
        height: 60px;
        margin-right: 15px;
        background-color: #f2f2f2;
    }

    .cart-item-details {
        margin-left: 40px;
    }

    .cart-item h2 {
        font-size: 1.3em;
        margin: 0;
        color: #444;
    }

    .cart-item p {
        font-size: 0.9em;
        margin: 5px 0;
        color: #555;
    }

    .empty-cart-message {
        font-size: 1em;
    }
}

/* Footer */
footer {
    position: relative; /* Make sure the footer is at the bottom */
    background-color: #f8f9fa;
    padding: 20px;
    text-align: center;
    border-top: 1px solid #e5e5e5;
}
