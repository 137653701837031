.navbar {
  position: fixed; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  width: 100%;
  padding: 1rem;
  z-index: 1000;
}

.navbar .logo {
  width: 60px; 
  height: 60px; 
  border-radius: 50%;
  object-fit: cover;
}

/* Menu principal pour les grands écrans */
.navbar-menu {
  display: flex;
  align-items: center;
  gap: 30px;
}

.navbar-menu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-menu li {
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s;
}

.navbar-menu li:hover,
.navbar-menu li.active {
  color: red; 
  border-bottom: 2px solid red;

}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 40px;
}

/* Sidebar pour les petits écrans */
.sidebar {
  position: fixed;
  top: 0;
  left: -250px; /* Commence hors de l'écran */
  width: 250px;
  height: 100%;
  background-color: #d2d2d2af;
  padding: 20px;
  transition: all 0.3s ease;
  z-index: 1100;
}

.sidebar.active {
  left: 0; /* Fait apparaître la barre latérale */
}

.close-icon {
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.sidebar-menu li {
  margin-bottom: 20px;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: color 0.3s, border-color 0.3s;
}

.sidebar-menu li:hover,
.sidebar-menu li.active {
  color: red; 
  border-bottom: 2px solid red;
}

/* Responsif pour les petits écrans */
@media (max-width: 600px) {
  .navbar-menu {
    display: none; /* Cacher le menu sur les petits écrans */
  }

  .menu-icon {
    display: block; /* Affiche l'icône de menu sur les petits écrans */
  }
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 40px;
}

.search, .cart {
  margin-right: 10px; 
}

.cart {
  position: relative; 
  display: inline-block;
  margin-right: 10px;
}

.icon {
  font-size: 35px;
  color: #6a6e7d;
  position: relative;
}

.icon:hover {
  color: red;
}

.cart-count {
  position: absolute;
  top: -4px;
  right: -10px;
  background-color: #ff0000; 
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px; 
  height: 20px; 
  transform: translate(50%, 0);
}

button {
  background-color: rgb(46, 46, 46);
  color: grey;
  padding: 10px 30px;
  border-radius: 25% 20%;
  border: 1px solid rgb(46, 46, 46);
  box-shadow: none;
  cursor: pointer;
  font-size: 16px;
  transition: 0.4s;
}

button:hover {
  transform: translateY(-10%);
  background-color: rgb(87, 86, 86);
}

.activeNavbar {
  background: rgba(255, 255, 255, 0.813);
  box-shadow: 0 2px 8px 2px rgba(233, 232, 232, 0.45);
  height: 75px;
}

/* Backdrop pour fermer la barre latérale */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
