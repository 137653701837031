/* OrderConfirmationModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
    box-sizing: border-box;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
}

.modal-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.modal-content p {
    font-size: 18px;
    margin-bottom: 20px;
}

.modal-content strong {
    font-weight: bold;
    color: #2c3e50;
}

.modal-content-button {
    border-radius: 25px;
    background-color: red;
    color: white;
    border: none;
    padding: 12px 30px;
    font-size: 16px;
    transition: 0.3s;
    cursor: pointer;
    display: inline-block;
}

.modal-content-button:hover {
    background-color: #c0392b;
    transform: translateY(-3px);
    color: white;
}

@media (max-width: 600px) {
    .modal-content {
        padding: 15px;
    }

    .modal-content h2 {
        font-size: 20px;
    }

    .modal-content p {
        font-size: 16px;
    }

    .modal-content-button {
        padding: 10px 20px;
        font-size: 14px;
    }
}
