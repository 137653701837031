.categories-container {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  animation: fadeIn 3s;
  position: relative;
}

.header-logo-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative; }

.header-logo {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  display: block;
}



@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.categories-container h2 {
  color: grey;
  font-weight: 500;
  font-size: 50px;
  text-align: center;
  z-index: 1;
  animation: fadeInUp 1s ease-out forwards; }

.explore-menu-test {
  max-width: 50%;
  color: grey;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
  animation: fadeInUp 1s ease-out 0.5s forwards; 
}

.categories-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories-scroll {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  z-index: 1;
}

.categories-scroll::-webkit-scrollbar {
  display: none; 
}



.scroll-icon {
  font-size: 40px;
  padding: 10px;
  cursor: pointer;
  color: grey;
}


.scroll-icon.left {
  margin-right: 10px;
}

.scroll-icon.right {
  margin-left: 10px;
}

.category-item {
  flex: 0 0 auto;
  width: 150px;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ddd;
  transition: transform 0.3s ease; 
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container:hover,
.image-container:active {
  transform: scale(1.1); 
}

.category-item p {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}

.category-item.selected .image-container {
  border: 2px solid red;
}

@media (max-width: 600px) {
  .categories-container h2 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .explore-menu-test {
    font-size: 13px;
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }

  .category-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allows items to wrap onto the next line */
  }

  .category-item {
    width: 100px; 
    margin: 0 12px;
    flex: 0 0 100px; /* Ensures the width is respected */
    display: flex;
    flex-direction: column; /* Stacks image and text vertically */
    align-items: center; /* Center-aligns items */
    text-align: center;
  }
  
  .image-container {
    width: 100px; 
    height: 100px; 
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ddd;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .category-item p {
    font-size: 14px;
    margin: 0; /* Removes any default margin */
    padding: 0.5rem; /* Adds space around the text */
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden; /* Hides overflow text if it exceeds the container width */
    text-overflow: ellipsis; /* Adds an ellipsis (...) if text overflows */
    text-align: center; /* Centers text horizontally */
  }
}

