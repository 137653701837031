.menu-container {
  padding: 20px;
}

.menu-container h2 {
  padding: 20px;
}

.menu-list {
  display: grid;
  gap: 20px;
  /* Espacement entre les éléments */
}

.menu-item {
  background-color: #f2f2f2;
  /* Arrière-plan gris par défaut */
  border-radius: 25px;
  /* Bordures arrondies */
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Ombre douce */
  display: flex;
  flex-direction: column;
  /* Afficher les éléments en colonne */
  transition: transform 0.3s ease;
  /* Animation de transition */
  margin-bottom: 1px;
  /* Ajout d'un espace entre chaque élément */
}

.menu-item:hover {
  transform: translateY(-5px);
  /* Déplacer l'élément vers le haut de 5 pixels au survol */
}

.menu-item img {
  max-width: 200px;
  max-height: 150px;
  height: auto;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.menu-item:hover img {
  transform: scale(1.1);
}

.menu-details {
  padding: 15px;
}

.menu-details h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.menu-details {
  display: flex;
  align-items: center;
}

.food {
  margin-left: 20px;
}

.out-of-stock-message {
  color: red;
}

.food h3 {
  margin-top: 0;
}

.food p {
  margin: 5px 0;
}

.food p:last-child {
  margin-bottom: 0;
}

.add-to-cart-button {
  border-radius: 25px;
  margin-top: 10px;
  align-self: center;
  background-color: red;
  color: white;
  border: 1px solid red;
  padding: 10px 30px;
  font-size: 16px;
  transition: 0.4s;
  cursor: pointer;
}

.add-to-cart-button:hover {
  transform: translateY(-10%);
  color: white;
  border-color: #c0392b;
  background-color: #c0392b;
}

.menu-item.out-of-stock {
  opacity: 0.5;
}

.menu-item.out-of-stock .add-to-cart-button {
  background-color: #ccc;
  border-color: #ccc;
  color: #999;
  cursor: not-allowed;
}

.menu-item.out-of-stock:hover {
  transform: none;
}

.menu-item.out-of-stock img {
  filter: grayscale(100%);
}

.menu-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.menu-popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  /* Ensure popup doesn't exceed viewport height */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.menu-popup-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  /* Réduisez cette valeur pour réduire l'espace global */
}

.menu-popup-header h2 {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  /* Ajustez selon vos besoins */
}

.menu-popup-header p {
  margin: 5px 0 0 0;
  /* Réduisez l'espace au-dessus de la description */
  padding: 0;
  font-size: 1em;
  /* Ajustez selon vos besoins */
}


.menu-popup img {
  width: 200px;
  /* Ajuste la taille de l'image */
  height: auto;
  margin-right: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.menu-popup img:hover {
  transform: scale(1.1);
}


.menu-popup h2 {
  margin: 0;
}

.menu-popup p {
  margin: 10px 0;
}

.menu-popup .price {
  font-size: 18px;
  font-weight: bold;
}

.menu-popup .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.menu-popup .content {
  margin-top: 20px;
  width: 100%;
}

.menu-popup .content h4 {
  font-size: 24px;
}

.menu-popup ul {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.menu-popup ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.menu-popup ul li:last-child {
  border-bottom: none;
}

.menu-popup ul li label {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.menu-popup ul li input {
  margin-right: 10px;
}

.menu-popup ul li span {
  font-weight: bold;
}


.center-button {
  display: block;
  margin: 0 auto;
  text-align: center;

}


.menu-popup .center-button .add-to-cart-button {
  width: auto;
  padding: 10px 30px;
  background-color: red;
  color: white;
  border: 1px solid red;
  font-size: 16px;
  transition: 0.4s;
  cursor: pointer;

}

.menu-popup .center-button .add-to-cart-button:hover {
  background-color: #c0392b;
  transform: translateY(-10%);
  color: white;
  border-color: #c0392b;
}

.quantity-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* Aligne les éléments à gauche */
}

.quantity-container span {
  margin: 0 10px;
  padding: 5px;
  text-align: center;
  min-width: 30px;
  font-size: 16px;
}

.quantity-container button {
  background-color: #ddd;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
}

.quantity-container button:hover {
  background-color: #ccc;
}

.quantity-container button:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .menu-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));

  }

  .menu-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: calc(50% - 10px);
  }

  .menu-item img {
    width: 100%;
    height: auto;
    border-radius: 25px 25px 0 0;
  }

  .menu-details {
    padding: 15px;
  }

  .menu-details h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .menu-details {
    display: flex;
    flex-direction: column;
  }

  .menu-item .add-to-cart-button {
    padding: 5px 10px;
    font-size: 14px;
  }

  .menu-popup {
    width: 400px;
    max-height: 80vh;
  }

  .menu-popup img {
    width: 40%;
    height: auto;
    margin-bottom: 10px;
  }

  .menu-popup-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .menu-popup-header img {
    margin-right: 20px;
    width: 120px;
    height: auto;
  }

  .menu-popup-header h2 {
    font-size: 1.2em;
    margin-top: 0;
  }

  .menu-popup-header p {
    margin-top: 5px;
    font-size: 0.9em;
  }

  .menu-popup ul li {
    margin-bottom: 10px;
  }

  .menu-popup ul li label {
    margin-left: 5px;
  }

  .center-button .add-to-cart-button {
    padding: 5px 15px;
    font-size: 14px;
  }

}


.styled-input {
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
  /* Light gray background */
  border: 1px solid #e0e0e0;
  /* Light gray border */
  border-radius: 5px;
  /* Rounded corners */
  font-size: 16px;
}

.styled-input::placeholder {
  color: #b0b0b0;
  /* Gray color for placeholder text */
}

.obligatoire {
  color: black;
  font-size: 0.8em;
  margin-left: 10px;
  font-weight: bold;
  background-color: lightgray;
  border-radius: 12px;
  padding: 2px 8px;
}