.footer {
  background-color: #f5f5f5;
  padding: 20px;
  width: 100%;
  margin-top: 80px;
  position: relative;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-logo {
  width: 100px;
  height: auto;
  margin-right: 20px;
}

.contact-info {
  text-align: left;
}

.contact-info h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  margin-left: 80px;
}

.contact-info p {
  margin: 5px 0;
  color: grey;
}

.contact-info strong {
  margin-right: 5px;
}

.address-link {
  color: grey;
  text-decoration: none;
}

.address-link:hover {
  text-decoration: underline;
}

hr {
  border-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.copyright {
  margin-top: 20px;
  color: #777;
  text-align: center;
}

/* Media queries pour les écrans de petite taille */
@media (max-width: 768px) {
  .contact-info h3 {
    margin-left: 10px;
    /* Réduit le décalage pour les écrans plus petits */
  }
}

@media (max-width: 480px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    margin-bottom: 20px;
  }

  .contact-info {
    text-align: center;
  }

  .contact-info h3 {
    margin-left: 0;
  }
}

.terms {
  margin-top: 20px;
}

.terms-title {
  margin-bottom: 10px;
  cursor: pointer;
  color: #555;
  text-decoration: none;
}

.terms-title:hover {
  text-decoration: underline;
}

.terms-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  position: relative;
}

.modal-content ul {
  list-style: none;
  padding: 0;
}

.modal-content li {
  margin-bottom: 10px;
}

.modal-content a {
  color: #333;
  text-decoration: none;
}

.modal-content a:hover {
  text-decoration: underline;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.copyright {
  margin-top: 20px;
}