.header {
    padding-top: 5rem;
    height: 80vh;
    width: 100%;
    /* background: url('./header_img.png');   */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}


/* Animation d'apparition du texte */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px); /* Déplacement vers le haut */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* Position d'origine */
    }
}

/* Styles de l'en-tête */
.header-contents {
    text-align: center;
    animation: fadeIn 1.5s ease-out; /* Animation d'apparition */
}

.header-contents h2 {
    color: grey;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 3.5rem;
    animation: fadeIn 1.5s ease-out forwards; /* Animation d'apparition */
    opacity: 0; /* Assurez-vous que l'élément est initialement invisible */
    animation-delay: 0.5s; /* Délai avant que l'animation commence */
}

.header-contents p {
    color: grey;
    opacity: 0.9;
    font-size: 15px;
    font-weight: 300;
    padding: 2rem 0;
    max-width: 50%;
    margin: auto;
    animation: fadeIn 1.5s ease-out forwards; /* Animation d'apparition */
    opacity: 0; /* Assurez-vous que l'élément est initialement invisible */
    animation-delay: 1s; /* Délai avant que l'animation commence */
}

/* Styles pour les boutons */
.btns {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.actionBtn {
    background-color: red;
    color: white;
    border: 1px solid red; 
    border-radius: 25px;
    padding: 10px 30px;
    font-size: 16px;
    transition: 0.4s;
    animation: fadeIn 1.5s ease-out forwards; /* Animation d'apparition */
    opacity: 0; /* Assurez-vous que l'élément est initialement invisible */
    animation-delay: 1.5s; /* Délai avant que l'animation commence */
}

.actionBtn:hover,
.actionBtn.active {
    background-color: #c0392b;
    color: white;
    border-color: #c0392b; 
}

.homeCard {
    width: 80%;
    padding: 1.5rem;
    border: 2px solid hsl(0, 0%, 96%);
    border-radius: 1rem;
    background: white;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 2px 8px 4px rgba(178, 178, 178, 0.45);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
}

.inputGroup {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    position: relative;
}

.inputItem {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.inputItem label {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 15px;
    color: hsl(240, 1%, 48%);
}

.inputItem input {
    background: hsl(330, 12%, 97%);
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    box-sizing: border-box;
    height: 40px;
}

.inputItem input::placeholder {
    font-size: 13px;
    opacity: 0.5;
    font-weight: 400;
}

.inputItem input:focus {
    outline: none;
}

.buttonContainer {
    display: flex;
    align-items: center;
    height: 40px;
}

.buttonContainer .actionBtn {
    background-color: red;
    color: white;
    border: 1px solid red;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-top: 19px;
}

.buttonContainer .actionBtn:hover,
.buttonContainer .actionBtn.active {
    background-color:#c0392b;
    color: white;
    border-color: #c0392b; 
}

.suggestion-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 0 0 10px 10px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}


.address-price {
    font-size: 14px;
    color: red;
    margin-left: 10px;
    animation: fadeIn 2s ease-out; 
    animation-delay: 1s;
    opacity: 0;
    animation-fill-mode: forwards;
}
.suggestion-list li {
    padding: 10px;
    cursor: pointer;
}

.suggestion-list li:hover {
    background: #f0f0f0;
    color: red;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: grey;
}

.close-icon:hover {
    color: red;
}

.close-icon:active {
    color: red;
}

.grayed-out {
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    opacity: 0.7;
}

.message.error {
    color: red;
    font-weight: bold;
    font-size: 14px;
}

/* Media Query for Samsung and iPhone Devices */
@media screen and (max-width: 767px) {
    .header-contents h2 {
        font-size: 2.5rem;
        line-height: 2.7rem;
    }

    .header-contents p {
        font-size: 14px;
        max-width: 90%; /* Wider for readability */
    }

    .homeCard {
        width: 95%; /* Almost full width */
        padding: 1.5rem; /* Adjust padding for smaller screens */
        top: 80%; /* Adjusted for a better fit on smaller screens */
    }

    .actionBtn {
        padding: 8px 20px; /* Adjust button padding for better fit */
        font-size: 14px;
    }

    .inputItem input {
        padding: 8px; /* Adjust input padding for smaller screens */
        font-size: 12px;
    }

    .address-price {
        font-size: 12px; /* Smaller text for smaller screens */
    }
}

/* Media Query for iPhone (Smallest Sizes) */
@media screen and (max-width: 375px) {
    .header-contents h2 {
        font-size: 2rem; /* Smaller font size for smaller screens */
        line-height: 2.2rem;
    }

    .header-contents p {
        font-size: 12px;
    }

    .homeCard {
        width: 100%; /* Full width for small screens */
        padding: 1rem; /* Less padding for compact design */
        top: 75%; /* Adjust for better visual balance */
    }

    .actionBtn {
        padding: 6px 16px; /* Smaller padding */
        font-size: 13px;
    }

    .inputItem input {
        padding: 6px;
        font-size: 11px;
    }

    .address-price {
        font-size: 11px;
    }
}