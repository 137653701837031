
.checkout {
    padding-top: 8rem;
   
}

.checkout-contents {
    max-width: 800px;
    padding-top: 20px; /* Ajustez la valeur selon vos besoins */
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    font-family: 'Arial', sans-serif;
    margin-bottom: 20px; /* Ajustez la valeur selon vos besoins */

}

h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #4a4a4a;
    text-align: center;
}

.form-contents {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input-contents {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.name-contents {
    display: flex;
    gap: 10px;
}

.input-contents input, 
.phone-input-contents input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-contents input:focus, 
.phone-input-contents input:focus {
    border-color: red;
    box-shadow: 0 0 10px rgba(209, 10, 10, 0.25);
    outline: none;
}

.phone-input-contents {
    display: flex;    
    align-items: center;
    gap: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 12px; 
}

.phone-input-contents select {
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: transparent;
    outline: none;
    appearance: none;
}

.phone-input-contents input {
    flex: 1;
    border: none;
    padding: 10px;
    outline: none;
}

.email-input-contents {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.email-input-contents input {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.address-input-contents {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px; 
}

.address-input-contents input {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.total-contents {
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column; /* Aligne les éléments verticalement */
    align-items: flex-start; /* Aligne les éléments à gauche */
    position: relative;
}
.checkout-details {
    margin-bottom: 20px;
}

.checkout-details h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
}

.checkout-details ul {
    list-style: none;
    padding: 20px;
}

.checkout-details li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.checkout-image {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 15px;
}

.checkout-info {
    flex-grow: 1;

}

.checkout-name {
    font-size: 18px;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 8px;
}

.checkout-quantity,
.checkout-price {
    display: block;
    font-size: 16px;
    color: #7f8c8d;
}

.checkout-extras {
    margin-top: 10px;
    font-size: 14px;
    color: #34495e;
}

.total-with-delivery,
.delivery-fee {
    margin: 10px 0;
    color: grey;
}

.total-price {
    font-size: 1.2em;
    font-weight: bold;
    color: #e63946;
    margin: 10px 0;
    align-self: flex-end; 
    width: 100%;
    text-align: right; 
}

.total-price-with-delivery {
    font-size: 1.2em;
    font-weight: bold;
    color: #e63946;
    margin: 10px 0;
}

.payment-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payment-method {
    margin-top: 20px;
}

.payment-method label {
    margin-right: 40px;
    font-size: 18px;
    cursor: pointer;
}

.payment-method input {
    margin-right: 10px;
}

.validate-button {
    display: flex;
    justify-content: center;
    border-radius: 25px;
    margin-top: 30px;
    background-color: red;
    color: white;
    border: 1px solid red;
    padding: 10px 40px;
    font-size: 18px;
    transition: 0.4s;
    cursor: pointer;
}

.validate-button:hover {
    background-color: #c0392b;
    transform: translateY(-10%);
    color: white;
    border-color: #c0392b;
}

.error-message {
    color: red;
    margin-bottom: 1em;
}

@media (max-width: 480px) {
    .checkout-contents {
        padding: 10px;
        margin: 10px;
    }

    h2 {
        font-size: 22px;
        margin-bottom: 10px;
    }

    .form-contents {
        gap: 10px;
    }

    .input-contents input, 
    .phone-input-contents input, 
    .email-input-contents input, 
    .address-input-contents input {
        padding: 10px;
        font-size: 14px;
    }

    .phone-input-contents select {
        padding: 8px;
        font-size: 14px;
    }

    .total-contents {
        padding: 8px;
        font-size: 14px;
    }

    .payment-method label {
        font-size: 14px;
        gap: 5px;
    }

    .validate-button {
        padding: 10px 15px;
        font-size: 14px;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .checkout-contents {
        padding: 15px;
    }

    h2 {
        font-size: 26px;
    }

    .form-contents {
        gap: 12px;
    }

    .input-contents input, 
    .phone-input-contents input, 
    .email-input-contents input {
        padding: 10px;
        font-size: 15px;
    }

    .phone-input-contents select {
        font-size: 15px;
        padding: 10px;
    }

    .total-contents {
        padding: 10px;
        font-size: 15px;
    }

    .payment-method label {
        font-size: 17px;
        margin-right: 30px;
    }

    .validate-button {
        padding: 9px 30px;
        font-size: 17px;
    }
}

@media (min-width: 769px) {
    .checkout-contents {
        padding: 20px;
    }

    h2 {
        font-size: 28px;
    }

    .form-contents {
        gap: 15px;
    }

    .input-contents input, 
    .phone-input-contents input, 
    .email-input-contents input {
        padding: 12px;
        font-size: 16px;
    }

    .phone-input-contents select {
        font-size: 16px;
        padding: 12px;
    }

    .total-contents {
        padding: 12px;
        font-size: 16px;
    }

    .payment-method label {
        font-size: 18px;
        margin-right: 40px;
    }

    .validate-button {
        padding: 10px 40px;
        font-size: 18px;
    }
}