.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.btn-container {
    display: flex;
    justify-content: center;
}

.modal h3 {
    margin-bottom: 20px;
}

.btn {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin: 10px; 
}

.btn.delete {
    background-color: red;
    color: white;
}

.btn.delete:hover {
    background-color:  #c0392b;
}

.btn.cancel {
    background-color: #ccc;
    color: black;
}

.btn.cancel:hover {
    background-color: #999;
}
